import '../styles/globals.css';
import { useEffect } from 'react';

import Script from 'next/script';
import type { AppProps } from 'next/app';

import { ChakraProvider } from '@chakra-ui/react';
import { SessionProvider } from 'next-auth/react';
import { SidebarDrawerProvider } from '../context/Sidebar';

import * as fbPixel from '../utils/facebook/facebookPixel';

import NextNProgress from 'nextjs-progressbar';

import { theme } from '../styles/theme';
import { useRouter } from 'next/router';
import { Toaster } from '@/shadcn-ui/components/ui/toaster';
import { AdditionalUserInfoProvider } from '@/context/AddtionalUserInfo';
import { AmplitudeAnalyticsContextProvider } from '@/context/AmplitudeAnalytics';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    fbPixel.pageview();

    const handleRouteChange = () => {
      setTimeout(() => {
        fbPixel.pageview();
      }, 0);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const AnalyticsScripts =
    process.env.NODE_ENV === 'production' ? (
      <>
        <Script
          id="meta-pixel"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
  `,
          }}
        />

        <Script
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
        />

        <Script id="google-analytics" strategy="lazyOnload">
          {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
    `}
        </Script>
      </>
    ) : null;

  return (
    <ChakraProvider theme={theme}>
      <SessionProvider>
        <AdditionalUserInfoProvider>
          <SidebarDrawerProvider>
            <AmplitudeAnalyticsContextProvider
              amplitudeApiKey={process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}
            >
              {AnalyticsScripts}
              <NextNProgress
                color="#7903EF"
                startPosition={0.3}
                stopDelayMs={200}
                height={5}
              />

              <Component {...pageProps} />
              <Toaster />
            </AmplitudeAnalyticsContextProvider>
          </SidebarDrawerProvider>
        </AdditionalUserInfoProvider>
      </SessionProvider>
    </ChakraProvider>
  );
}

export default MyApp;
